<template>
    <div>
        <Preloader v-if="loader" />
        <h1 class="mb-4 page-title">Редактирование категории</h1>

        <div>
            <b-form class="g-1 p-4" @submit.prevent="submitForm" @reset.prevent="resetForm">
                <div class="widget row">
                    <b-form-group
                        id="input-group-1"
                        label="Название:"
                        class="col-6"
                        label-for="input-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="form.title"
                            type="text"
                            placeholder="Название"
                            required
                        />
                    </b-form-group>

                    <b-form-group
                        label="Язык валюты:"
                        class="col-6"
                        label-for="input-2"
                    >
                        <b-form-input
                            id="input-group-2"
                            v-model="form.currency"
                            type="text"
                            placeholder="Валюта:"
                            disabled
                            required
                        />
                    </b-form-group>

                    <b-form-group
                        label="Доплата:"
                        class="col-2 page-category-surcharge"
                        label-for="input-group-3"
                    >
                        <b-form-checkbox
                            id="input-group-3"
                            name="input-group-3"
                            v-model="form.surcharge"
                        />
                    </b-form-group>

                    <b-form-group
                        id="input-group-4"
                        label="Значение оклада:"
                        class="col-4"
                        label-for="input-4"
                    >
                        <input
                            id="input-4"
                            class='form-control'
                            type="number"
                            step=".01"
                            placeholder="Значение оклада"
                            :disabled='!form.surcharge'
                            @blur="handleBlurOnNumber($event, 'surcharge_value')"
                            v-model='form.surcharge_value'
                        />
                    </b-form-group>

                    <b-form-group
                        id="input-group-5"
                        label="Процент:"
                        class="col-6"
                        label-for="input-5"
                    >
                        <input
                            id="input-5"
                            class='form-control'
                            type="number"
                            step=".01"
                            placeholder="Процент"
                            :disabled='!form.surcharge'
                            @blur="handleBlurOnNumber($event, 'surcharge_percent')"
                            v-model='form.surcharge_percent'
                        />
                    </b-form-group>

                    <b-form-group
                      id="input-group-5"
                      label="Уроков до повышенной ставки:"
                      class="col-6"
                      label-for="input-5"
                    >
                      <input
                        id="input-5"
                        class='form-control'
                        type="number"
                        step="1"
                        placeholder="Уроков до повышенной ставки:"
                        v-model='form.lesson_count_rule'
                      />
                    </b-form-group>
                </div>

                <div class="row mb-4">
                    <h4 class="page-title m-0">Ставки</h4>
                </div>

                <div class="widget row">
                    <div class="col-12">
                        <div
                            v-for="(priceLang, idx) in prices"
                            :key="idx"
                            class="mb-4"
                        >
                            <h4 class="page-title m-2">Язык преподавания - {{ idx }}</h4>
                            <table class="table table-hover">
                                <thead class="table_head">
                                <tr>
                                    <th>Продолжительность</th>
                                    <th>Язык преподавания</th>
                                    <th>Цена</th>
                                    <th>Повышенная цена</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(price, priceKey) in priceLang"
                                    :key="`price-${price.language}-${price.duration}-${priceKey}`"
                                    :id="`price-${price.language}-${price.duration}-${priceKey}`"
                                >
                                    <td>
                                        <b-form-input
                                            :id="`price-${price.language}-${price.duration}-${priceKey}-duration`"
                                            v-model="price.duration"
                                            type="text"
                                            disabled
                                            required
                                        />
                                    </td>
                                    <td>
                                        <b-form-input
                                            :id="`price-${price.language}-${price.duration}-${priceKey}-language`"
                                            v-model="price.language"
                                            type="text"
                                            disabled
                                            required
                                        />
                                    </td>
                                    <td>
                                        <b-form-input
                                            :id="`price-${price.language}-${price.duration}-${priceKey}-price`"
                                            v-model="price.price"
                                            type="number"
                                            min="0"
                                            placeholder="Цена"
                                            required
                                        />
                                    </td>
                                  <td>
                                        <b-form-input
                                            :id="`price-${price.language}-${price.duration}-${priceKey}-price`"
                                            v-model="price.increased_price"
                                            type="number"
                                            min="0"
                                            placeholder="Цена"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <b-form-group class="row justify-content-end">
                    <b-button type="submit" class="mr-2" variant="success">Сохранить</b-button>
                    <b-button type="reset" variant="primary">Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>

import Preloader from "@/components/Preloader";
import Switcher from "@/components/Switcher";

export default {
    name: "SalaryEdit",
    components: { Preloader, Switcher },
    data() {
        return {
            loader: false,
            form: {
                title: null,
                currency: "RUB",
                surcharge: false,
                surcharge_value: 0,
                surcharge_percent: 0,
                lesson_count_rule: 0
            },
            currencies: null,
            prices: [],
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loader = true;
                const { data } = await this.$axios.get(`crm/salary-categories/${this.$route.params.id}/edit`);

                for (const [key, val] of Object.entries(data.category)) {
                    if (key === "prices") continue;
                    if (key === "surcharge") {
                        this.form[key] = Number(val) === 1;
                        continue;
                    }
                    this.form[key] = val;
                }

                this.currencies = data.currencies;
                this.prices = data.prices;
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log("cant fetch data: ", ex);
            }
        },
        async submitForm() {
            try {
                const prices = [];

                if (Object.values(this.prices) && Object.values(this.prices).length > 0) {
                    for (const category of Object.values(this.prices)) {
                        for (const price of Object.values(category)) {
                            prices.push(price);
                        }
                    }
                }

                const formData = {
                    'category': this.form,
                    'prices': prices,
                };

                await this.$axios.post(`crm/salary-categories/${this.$route.params.id}`, formData, {
                    params: {
                        _method: "patch",
                    },
                });
                await this.fetchData();
                this.$toasted.success("Запись обновлена", { position: "bottom-right" });
            } catch (ex) {
                console.log('cant update salary category: ', ex);
                let errMsg = 'Ошибка сохранения';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, { position: "bottom-right" });
            }
        },
        resetForm() {
            this.$router.back();
        },
        handleBlurOnNumber(e, field) {
            let val = e.target.value;

            if (val < 0 || val === '' || val === ' ') {
                e.target.value = 0;
                this.form[field] = 0;
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn .glyphicon {
    top: 0 !important;
}

.header-profile {
    width: 120px;
    text-align: center;
}

@mixin badge($bg, $col) {
    background: $bg;
    color: $col;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.badge {
    &-noprofile {
        @include badge(#ee8c62, #fff);
    }

    &-published {
        @include badge(#70dba6, #000);
    }

    &-edit {
        @include badge(#faed5e, #000);
    }
}

.page-title {
    font-weight: bold;
}
</style>